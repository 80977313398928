import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import News from "../views/news/News.vue";
import Newlist from "../views/newlist/Newlist.vue";
import Articledetail from "../views/articledetail/Articledetail.vue";
import Taglist from "../views/taglist/Taglist.vue";
import Searchlist from "../views/searchlist/Searchlist.vue";
import Cooperate from "../views/cooperate/Cooperate.vue";
import Activity from "../views/activity/Activity.vue";
import Summit from "../views/active_wsfh/Summit.vue";
import Wow from "../views/active_wsfh/Wow.vue";
import WowBack from "../views/active_wsfh/WowBack.vue";
import Remould from "../views/active_wsfh/remould.vue";
import RemouldBack from "../views/active_wsfh/remouldBack.vue";
import Mini from "../views/market_products/mini.vue";
import Shortvideo from "../views/market_products/shortvideo.vue";
import Shortvideos from "../views/market_products/shortvideos.vue";
import Shortvideoss from "../views/market_products/shortvideoss.vue"
import Zxjy from "../views/market_products/zxjy.vue";
import Linkage from "../views/market_products/linkage.vue";
import StrawBag from "../views/market_products/strawBag.vue";
import Foodie from "../views/market_products/foodie.vue";
import Nryx from "../views/market_products/nryx.vue";
import SuperHot from "../views/market_products/superHot.vue";
import Train from "../views/market_products/train.vue";
import Kuang from "../views/market_products/kuang.vue";
import Yljzt from "../views/market_products/yljzt.vue";
import Tttm from "../views/market_products/tttm.vue";
import Twmss from "../views/market_products/twmss.vue";
import Kdmj from "../views/market_products/kdmj.vue";
import Pyramid from "../views/market_products/pyramid.vue";
import Sttj from "../views/market_products/sttj.vue";
import Alimama from "../views/market_products/alimama.vue";
import Accompany from "../views/market_products/accompany.vue";
import Serve from "../views/market_products/serve.vue";
import Live from "../views/liveBroadcast/live.vue";
import Wxmcn from "../views/wxmcn/wxmcn.vue";
import Idaxue from "../views/idaxue/index.vue";
import Wangwang from "../views/wangwang/index.vue";
import Stroll from "../views/flybird/stroll.vue";
import Zebra from "../views/product/zebra.vue";
import Newispower from "../views/product/newispower.vue";
import NewispowerReview from "../views/product/newispowerReview.vue";
import Caicai from "../views/product/caicai.vue";
import Caicai2 from "../views/product/caicai2.vue";
import Timing from "../views/product/timing.vue";
import Zzc from "../views/product/zzc.vue";
import txwsZzc from "../views/txws/zzc.vue";
import Zh from "../views/txws/zh.vue";
import Tbzh from "../views/twyx/tbzh.vue";
import Visual from "../views/twyx/visual.vue";
import txwsZebra from "../views/txws/zebra.vue";
import StoreWide from "../views/product/storewindes.vue";
import Allproducts from "../views/product/allproducts.vue";
import Zhyxs from "../views/market_products/zhyxs.vue";
import Sjpxs from "../views/market_products/sjpxs.vue";
import Aboutus from "../views/market_products/aboutus.vue";
import Nrst from "../views/market_products/nrst.vue";
import Talk from "../views/market_products/talk.vue";
Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "天下网商-传播新商业,赋能新商业,成就新商业",
        },
    },
    {
        path: "/news",
        name: "News",
        component: News,
        meta: {
            title: "新闻-天下网商-传播新商业,赋能新商业,成就新商业",
        },
    },
    {
        path: "/newlist/:nid",
        name: "Newlist",
        component: Newlist,
    },
    {
        path: "/articledetail/:cid",
        name: "Articledetail",
        component: Articledetail,
    },
    {
        path: "/taglist/:tid",
        name: "Taglist",
        component: Taglist,
    },
    {
        path: "/searchlist/:keywords",
        name: "Searchlist",
        component: Searchlist,
    },
    {
        path: "/cooperate",
        name: "Cooperate",
        component: Cooperate,
        meta: {
            title: "业务合作-天下网商-传播新商业,赋能新商业,成就新商业",
        },
    },
    {
        path: "/activity",
        name: "Activity",
        component: Activity,
        meta: {
            title: "活动-天下网商-传播新商业,赋能新商业,成就新商业",
        },
    },
    {
        path: "/activity/wsfh/summit",
        name: "Summit",
        component: Summit,
    },
    {
        path: "/activity/wsfh/wow",
        name: "Wow",
        component: Wow,
    },
    {
        path: "/activity/wsfh/wowback",
        name: "WowBack",
        component: WowBack,
    },
    {
        path: "/activity/wsfh/remouldback",
        name: "RemouldBack",
        component: RemouldBack,
    },
    {
        path: "/activity/wsfh/remould",
        name: "Remould",
        component: Remould,
    },
    {
        path: "/market/products/mini",
        name: "Mini",
        component: Mini,
    },
    {
        path: "/market/products/shortvideo",
        name: "Shortvideo",
        component: Shortvideo,
    },
    {
        path: "/market/products/shortvideos",
        name: "Shortvideos",
        component: Shortvideos,
    },
    {
        path: "/market/products/shortvideoss",
        name: "Shortvideoss",
        component: Shortvideoss,
    },
    {
        path: "/market/products/zxjy",
        name: "Zxjy",
        component: Zxjy,
    },
    {
        path: "/market/products/sjpxs",
        name: "Sjpxs",
        component: Sjpxs,
    },
    {
        path: "/market/products/aboutus",
        name: "Aboutus",
        component: Aboutus,
    },
    {
        path: "/market/products/zhyxs",
        name: "Zhyxs",
        component: Zhyxs,
        meta: {
            title: "天下网商-智慧营销",
        },
    },
    {
        path: "/market/products/nrst",
        name: "Nrst",
        component: Nrst,
        meta: {
            title: "天下网商-内容生态",
        },
    },
    {
        path: "/market/products/talk",
        name: "Talk",
        component: Talk,
        meta: {
            title: "天下网商-对话创始人",
        },
    },
    {
        path: "/market/products/linkage",
        name: "Linkage",
        component: Linkage,
    },
    {
        path: "/market/products/strawBag",
        name: "StrawBag",
        component: StrawBag,
    },
    {
        path: "/market/products/foodie",
        name: "Foodie",
        component: Foodie,
    },
    {
        path: "/market/products/nryx",
        name: "Nryx",
        component: Nryx,
    },
    {
        path: "/market/products/superHot",
        name: "SuperHot",
        component: SuperHot,
    },
    {
        path: "/market/products/train",
        name: "Train",
        component: Train,
    },
    {
        path: "/market/products/kuang",
        name: "Kuang",
        component: Kuang,
    },
    {
        path: "/market/products/yljzt",
        name: "Yljzt",
        component: Yljzt,
    },
    {
        path: "/market/products/tttm",
        name: "Tttm",
        component: Tttm,
    },
    {
        path: "/market/products/twmss",
        name: "Twmss",
        component: Twmss,
    },
    {
        path: "/market/products/kdmj",
        name: "Kdmj",
        component: Kdmj,
    },
    {
        path: "/market/products/pyramid",
        name: "Pyramid",
        component: Pyramid,
    },
    {
        path: "/market/products/serve",
        name: "Serve",
        component: Serve,
    },
    {
        path: "/market/products/visual",
        name: "Visual",
        component: Visual,
    },
    {
        path: "/market/products/sttj",
        name: "Sttj",
        component: Sttj,
        meta: {
            title: "天字网络手淘推荐",
        },
    },
    {
        path: "/alimama/11list",
        name: "Alimama",
        component: Alimama,
    },
    {
        path: "/market/products/accompany",
        name: "Accompany",
        component: Accompany,
    },
    {
        path: "/flybird/live",
        name: "Live",
        component: Live,
    },
    {
        path: "/flybird/stroll",
        name: "Stroll",
        component: Stroll,
    },
    {
        path: "/wxmcn",
        name: "Wxmcn",
        component: Wxmcn,
    },
    {
        path: "/idaxue/class/:classId",
        name: "Idaxue",
        component: Idaxue,
    },
    {
        path: "/wang/w/:customerName",
        name: "Wangwang",
        component: Wangwang,
        meta: {
            title: "ATHENA管理平台",
        },
    },
    {
        path: "/tzyx/zebra/product/:id",
        name: "Zebra",
        component: Zebra,
    },
    {
        path: "/activity/wsfh/newispower",
        name: "Newispower",
        component: Newispower,
    },
    {
        path: "/activity/wsfh/newispower/review",
        name: "NewispowerReview",
        component: NewispowerReview,
    },
    {
        path: "/activity/wsfh/timing",
        name: "Timing",
        component: Timing,
    },
    {
        path: "/activity/caicai/list",
        name: "Caicai",
        component: Caicai,
    },
    {
        path: "/activity/caicai/list2",
        name: "Caicai2",
        component: Caicai2,
    },
    {
        path: "/tzyx/zebra/zzc",
        name: "Zzc",
        component: Zzc,
    },
    {
        path: "/twyx/tbzh",
        name: "Tbzh",
        component: Tbzh,
    },
    {
        path: "/tzyx/zebra/storewindes",
        name: "StoreWidestorewindes",
        component: StoreWide,
    },

    {
        path: "/tzyx/zebra/allproducts",
        name: "Allproducts",
        component: Allproducts,
    },
    {
        path: "/hz/zebra/zzc",
        name: "txwsZzc",
        component: txwsZzc,
        meta: {
            title: "淘宝智慧营销投放产品服务",
        },
    },
    {
        path: "/hz/zebra/zh",
        name: "Zh",
        component: Zh,
        meta: {
            title: "淘宝智慧营销投放产品服务",
        },
    },
    {
        path: "/txws/zebra/product/:id",
        name: "txwsZebra",
        component: txwsZebra,
    },
];


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'routerlinkactive'
});

// router.afterEach(() => {
//   // console.log(window.location.href)
//   var url = window.location.href
//   this.$https
//         .get(this.host + `/api/product/wechat/getSign?url=${url}`)
//         .then(function(res) {
//           console.log(res)
//         });
// })

export default router;