<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i4/O1CN01gwICUc1jN5CRnnbcX_!!6000000004535-2-tps-1920-5589.png"
          alt=""
        />
        <div class="first" @click="goshop"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    goshop() {
      window.open("https://www.zhipin.com/gongsi/9736d55db21f98f61nd839u0.html?ka=search_rcmd_company_9736d55db21f98f61nd839u0_custompage");
    },
  },
};
</script>

<style scoped>
.first {
  position: absolute;
  width: 11.25%;
  height: 2.74%;
  top: 91.2%;
  left: 67.61%;
  cursor: pointer;
}
.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
</style>