var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bcgcolor"},[_c('header',[_c('img',{attrs:{"width":"100%","src":"https://img.alicdn.com/imgextra/i1/O1CN01Z68xcm1JDkp1G7kJs_!!6000000000995-2-tps-1920-936.png","alt":""}}),_c('div',{staticClass:"headimg",on:{"click":_vm.gobuy}},[_c('img',{attrs:{"width":"90%","src":"https://img.alicdn.com/imgextra/i3/O1CN01tiK80P1pnXR5dSPhw_!!6000000005405-2-tps-438-119.png","alt":""}})])]),_c('section',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"main8"},[_c('div',{staticClass:"buysum"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i4/O1CN01zT958W1vyswWlI1Gl_!!6000000006242-2-tps-1122-1068.png","alt":""}}),_c('div',{attrs:{"id":"buy"}}),_c('div',{staticClass:"buy",on:{"click":_vm.buy}})])]),_vm._m(7),_vm._m(8),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main1"},[_c('img',{attrs:{"width":"1010px","src":"https://img.alicdn.com/imgextra/i1/O1CN01CMoLbK1szOZNYvhxj_!!6000000005837-2-tps-1122-336.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main2"},[_c('img',{attrs:{"width":"1060px","src":"https://img.alicdn.com/imgextra/i2/O1CN01AJUkCC270DuHhXc5e_!!6000000007734-2-tps-1178-891.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main3"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i3/O1CN01S9XRPB29tl8LTmywu_!!6000000008126-2-tps-1106-960.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main4"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i4/O1CN01a4nJ7f1yb19NZ9aLm_!!6000000006596-2-tps-1106-960.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main5"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i3/O1CN01abRGAK1RpKCnlVfmH_!!6000000002160-2-tps-1106-960.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main6"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i4/O1CN016x6ls91Icehoo7D4W_!!6000000000914-2-tps-1106-960.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main7"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i2/O1CN01P172tJ1sfhVZTFiMx_!!6000000005794-2-tps-1106-961.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main9"},[_c('div',{staticClass:"videosum"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i4/O1CN01AJW5ud1LCUw7IgQOf_!!6000000001263-2-tps-1122-656.png","alt":""}}),_c('div',{staticClass:"video"},[_c('video',{attrs:{"controls":"","width":"100%","src":"https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%A8%E5%9F%9F%E8%81%94%E5%8A%A8/%E5%85%A8%E5%B1%8F%E9%A1%B5%E8%A7%86%E9%A2%91%E6%A1%88%E4%BE%8BFinalVideo_1620441219.309778.MP4"}})]),_c('div',{staticClass:"video2"},[_c('video',{attrs:{"controls":"","width":"100%","src":"https://cloud.video.taobao.com/play/u/351149007/p/1/e/6/t/1/304136776723.mp4"}})]),_c('div',{staticClass:"video3"},[_c('video',{attrs:{"controls":"","poster":"https://img.alicdn.com/imgextra/i2/O1CN01b6Xw261ODMWyBfopW_!!6000000001671-2-tps-141-254.png","width":"100%","src":"https://cloud.video.taobao.com/play/u/283601870/p/1/e/6/t/1/229138431322.mp4"}})]),_c('div',{staticClass:"video4"},[_c('video',{attrs:{"controls":"","width":"100%","src":"https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%A8%E5%9F%9F%E8%81%94%E5%8A%A8/FinalVideo_1620442519.437095.MP4"}})]),_c('div',{staticClass:"video5"},[_c('video',{attrs:{"controls":"","poster":"https://img.alicdn.com/imgextra/i1/O1CN01I9HNwM1vL2UNoxSrc_!!6000000006155-2-tps-147-258.png","width":"100%","src":"https://cloud.video.taobao.com/play/u/4246583232/p/1/e/6/t/1/31\n2803933806.mp4"}})]),_c('div',{staticClass:"video6"},[_c('video',{attrs:{"controls":"","poster":"https://img.alicdn.com/imgextra/i3/O1CN01HLFO9k1z8uSiUMD1N_!!6000000006670-2-tps-149-261.png","width":"100%","src":"https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%A8%E5%9F%9F%E8%81%94%E5%8A%A8/%E9%B9%8C%E9%B9%91%E7%9A%AE%E8%9B%8B%E5%AE%9A%E7%A8%BF.MP4"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main10"},[_c('img',{attrs:{"width":"1000px","src":"https://img.alicdn.com/imgextra/i2/O1CN01DFBjnS1y8cbAie6pz_!!6000000006534-2-tps-1122-682.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main11"},[_c('img',{attrs:{"width":"1070px","src":"https://img.alicdn.com/imgextra/i2/O1CN01WjLEIW1Cjd2omqvMw_!!6000000000117-2-tps-1208-1237.png","alt":""}})])
}]

export { render, staticRenderFns }