import { render, staticRenderFns } from "./caicai.vue?vue&type=template&id=22d1e446&scoped=true"
import script from "./caicai.vue?vue&type=script&lang=js"
export * from "./caicai.vue?vue&type=script&lang=js"
import style0 from "./caicai.vue?vue&type=style&index=0&id=22d1e446&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d1e446",
  null
  
)

export default component.exports