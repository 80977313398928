var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bcgcolor"},[_c('section',[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i4/O1CN015scOQB1YxINfyQ9lv_!!6000000003125-2-tps-1169-732.png","alt":""}}),_c('div',{staticClass:"headimg",on:{"click":_vm.gobuy}},[_c('img',{attrs:{"width":"220%","src":"https://img.alicdn.com/imgextra/i2/O1CN01k47HFO1pkKdjNZDBM_!!6000000005398-2-tps-913-184.png","alt":""}})])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main1"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i4/O1CN01dju3EJ1NPtj0FUyOs_!!6000000001563-2-tps-1356-817.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main2"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i2/O1CN01MArDHp1CzCTs34jUt_!!6000000000151-2-tps-1213-762.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main3"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i1/O1CN01ukjtVx268dVTok0l7_!!6000000007617-2-tps-1365-892.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main4",attrs:{"id":"buy"}},[_c('div',{staticClass:"buysum"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i3/O1CN01uVlwAq1enZkI8OhPz_!!6000000003916-2-tps-1180-835.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main6"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i1/O1CN01eyILcK1oLlmWvhkMW_!!6000000005209-2-tps-1211-514.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main7"},[_c('img',{attrs:{"src":"https://img.alicdn.com/imgextra/i1/O1CN01EFy1e51yOeRqNPLid_!!6000000006569-2-tps-1211-543.png","alt":""}})])
}]

export { render, staticRenderFns }