var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{ref:"box",staticClass:"midtable",on:{"&scroll":function($event){return _vm.getScroll($event)}}},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.activated === 6),expression:"this.activated === 6"}],staticClass:"middletablesum"},_vm._l((_vm.tableData6),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.activated === 7),expression:"this.activated === 7"}],staticClass:"middletablesum"},_vm._l((_vm.tableData7),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.activated === 8),expression:"this.activated === 8"}],staticClass:"middletablesum"},_vm._l((_vm.tableData8),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"14vw"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.activated === 9),expression:"this.activated === 9"}],staticClass:"middletablesum"},_vm._l((_vm.tableData9),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0),(this.activated === 10)?_c('div',{staticClass:"middletablesum"},_vm._l((_vm.tableData10),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0):_vm._e(),(this.activated === 11)?_c('div',{staticClass:"middletablesum"},_vm._l((_vm.tableData11),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0):_vm._e(),(this.activated === 12)?_c('div',{staticClass:"middletablesum"},_vm._l((_vm.tableData12),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0):_vm._e(),(this.activated === 13)?_c('div',{staticClass:"middletablesum"},_vm._l((_vm.tableData13),function(item,index){return _c('div',{key:index,class:{ double: index % 2 === 0 }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.activeindex),expression:"index !== activeindex"}],class:{
              middletable: true,
              middletop: index - 1 === _vm.activeindex,
            }},[_c('div',{staticClass:"tr1"},[_vm._v(_vm._s(item.serialNum))]),_c('div',{staticClass:"tr2"},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.picUrl}})]),_c('div',{staticClass:"tr4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tr3"},[_vm._v(" "+_vm._s(item.location)+" ")]),_c('div',{staticClass:"tr5",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"tr6"},[_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.activeindex),expression:"index === activeindex"}],staticClass:"activeindex"},[_c('div',{staticClass:"tr7"},[_vm._v(_vm._s(item.serialNum))]),_c('div',[_c('img',{staticStyle:{"width":"30vw","margin-left":"5px"},attrs:{"src":item.picUrl}})]),_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"activetitle1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"activetitle2"},[_vm._v(_vm._s(item.location))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"activeprice"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"activecopy",on:{"click":function($event){return _vm.handleCopy(item.password)}}},[_vm._v(" 复制口令 ")])])])])])])}),0):_vm._e()]),_c('div',{staticClass:"btmtabs"},[_c('div',{class:{
          tabs: this.activated !== 6,
          activated: this.activated === 6,
        },on:{"click":function($event){return _vm.gotab(6)}}},[_vm._v(" 必买爆款 ")]),_c('div',{class:{
          tabs: this.activated !== 7,
          activated: this.activated === 7,
        },on:{"click":function($event){return _vm.gotab(7)}}},[_vm._v(" 全国通兑 ")]),_c('div',{class:{
          tabs: this.activated !== 8,
          activated: this.activated === 8,
        },on:{"click":function($event){return _vm.gotab(8)}}},[_vm._v(" 长三角 ")]),_c('div',{class:{
          tabs: this.activated !== 9,
          activated: this.activated === 9,
        },on:{"click":function($event){return _vm.gotab(9)}}},[_vm._v(" 海南云南 ")]),_c('div',{class:{
          tabs: this.activated !== 10,
          activated: this.activated === 10,
        },on:{"click":function($event){return _vm.gotab(10)}}},[_vm._v(" 西北川渝 ")]),_c('div',{class:{
          tabs: this.activated !== 11,
          activated: this.activated === 11,
        },on:{"click":function($event){return _vm.gotab(11)}}},[_vm._v(" 东北滑雪 ")]),_c('div',{class:{
          tabs: this.activated !== 12,
          activated: this.activated === 12,
        },on:{"click":function($event){return _vm.gotab(12)}}},[_vm._v(" 珠三角 ")]),_c('div',{class:{
          tabs: this.activated !== 13,
          activated: this.activated === 13,
        },on:{"click":function($event){return _vm.gotab(13)}}},[_vm._v(" 其他 ")])])]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"70%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticStyle:{"text-align":"center","font-weight":"500","font-size":"15px"}},[_vm._v(" 复制成功，快去飞🐷抢购吧 ")]),_c('div',{staticClass:"ok",on:{"click":_vm.close}},[_vm._v("OK")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topth"},[_c('div',{staticClass:"topth1"}),_c('div',{staticClass:"topth2"},[_vm._v("图片")]),_c('div',{staticClass:"topth2"},[_vm._v("商品名称")]),_c('div',{staticClass:"topth2"},[_vm._v("目的地")]),_c('div',{staticClass:"topth2"},[_vm._v("最低活动")]),_c('div',{staticClass:"topth2"},[_vm._v("复制口令")])])
}]

export { render, staticRenderFns }