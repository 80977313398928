<template>
  <div>
    <section id="talk">
      <div class="main1">
        <div id="img-item-1" class="item">
          <img src="./img/1.png" alt="banner" width="100%" />
        </div>
        <div id="img-item-2" class="item">
          <img src="./img/2.png" alt="img" width="100%" />
        </div>
        <div id="img-item-3" class="item">
          <img src="./img/3.png" alt="banner" width="100%" />
        </div>
        <div id="img-item-4" class="item">
          <img src="./img/4.png" alt="img" width="100%" />
        </div>
        <div id="img-item-5" class="item">
          <img src="./img/5.png" alt="img" width="100%" />
          <div>
            <div class="interviewvedio firstvedio">
              <video
                :src="vedios[0]"
                width="100%"
                controls
                poster="./img/shijing.png"
              ></video>
            </div>
            <div class="interviewartical firstartical">
              <a :href="articals[0]" target="_blank"
                ><img src="./img/clicktoread.png" width="100%"
              /></a>
            </div>
            <div class="interviewaudio firstaudio">
              <a :href="audiolinks[0]" target="_blank"
                ><img src="./img/listentoaudio.png" width="100%"
              /></a>
            </div>
          </div>

          <div>
            <div class="interviewvedio secondvedio">
              <video
                :src="vedios[1]"
                width="100%"
                controls
                poster="./img/zhujunming.png"
              ></video>
            </div>
            <div class="interviewartical secondartical">
              <a :href="articals[1]" target="_blank"
                ><img src="./img/clicktoread.png" width="100%"
              /></a>
            </div>
            <div class="interviewaudio secondaudio">
              <a :href="audiolinks[1]" target="_blank" style="pointer-events:none;"
                ><img src="./img/listentoaudio.png" width="100%"
              /></a>
            </div>
          </div>

          <div>
            <div class="interviewvedio thirdvedio">
              <video
                :src="vedios[2]"
                width="100%"
                controls
                poster="./img/yuguang.png"
              ></video>
            </div>
            <div class="interviewartical thirdartical">
              <a :href="articals[2]" target="_blank"
                ><img src="./img/clicktoread.png" width="100%"
              /></a>
            </div>
            <div class="interviewaudio thirdaudio">
              <a :href="audiolinks[2]" target="_blank"
                ><img src="./img/listentoaudio.png" width="100%"
              /></a>
            </div>
          </div>

          <div>
            <div class="interviewvedio fourthvedio">
              <video
                :src="vedios[3]"
                width="100%"
                controls
                poster="./img/zhangyuxiang.png"
              ></video>
            </div>
            <div class="interviewartical fourthartical">
              <a :href="articals[3]" target="_blank"
                ><img src="./img/clicktoread.png" width="100%"
              /></a>
            </div>
            <div class="interviewaudio fourthaudio">
              <a :href="audiolinks[3]" target="_blank"
                ><img src="./img/listentoaudio.png" width="100%"
              /></a>
            </div>
          </div>
        </div>
        <div id="img-item-6" class="items">
          <div class="previous" @click="switchToimg(-1)"></div>
          <div class="first" @click="switchToimg(0)"></div>
          <div class="second" @click="switchToimg(1)"></div>
          <div class="third" @click="switchToimg(2)"></div>
          <div class="next" @click="switchToimg(3)"></div>
          <div v-show="active === 0" class="meal-item">
            <img src="./img/guests1.png" alt="banner" width="100%" />
          </div>
          <div v-show="active === 1" class="meal-item">
            <img src="./img/guests2.png" alt="套餐" width="100%" />
          </div>
          <div v-show="active === 2" class="meal-item">
            <img src="./img/guests3.png" alt="套餐" width="100%" />
          </div>
        </div>
        <div id="img-item-7" class="item">
          <img src="./img/7.png" alt="img" width="100%" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vedios: [
        "https://cloud.video.taobao.com/vod/TYNNDKEmhmm2n_55RdmpwQt-eUzY9jHqf9WENYp7k_k.mp4",
        "https://cloud.video.taobao.com/vod/cNjur5DluB0JKgWqvYfm8bHpvnfnDSZMDXyUnlDmifM.mp4",
        "https://cloud.video.taobao.com/vod/25qhEEozZ5NhvkKwxgjLjzPXEUu1eCPnRSNp0-0p0OM.mp4",
        "https://cloud.video.taobao.com/vod/xVDZRSaKW0zqbSWZe1YeWOEW2XIyFbTQGrwLtVRLm9M.mp4",
      ],
      articals: [
        "http://www.iwshang.com/articledetail/270335",
        "http://www.iwshang.com/articledetail/270387",
        "http://www.iwshang.com/articledetail/270400",
        "https://tianxiawangshang.com/articledetail/270596"
      ],
      audiolinks: [
        "https://m.ximalaya.com/sound/732908294?from=pc",
        "",
        "https://m.ximalaya.com/sound/731678223?from=pc",
        "https://m.ximalaya.com/sound/760304267?from=pc"
      ],
      imgArr: [
        "https://img.alicdn.com/imgextra/i2/O1CN0128Bp2D1Ie24XoLKPU_!!6000000000917-2-tps-1249-799.png",
        "https://img.alicdn.com/imgextra/i2/O1CN014IOvs51StHPp4rueE_!!6000000002304-2-tps-1249-799.png",
        "https://img.alicdn.com/imgextra/i3/O1CN01hCJmtR1RKdqZEt9k3_!!6000000002093-2-tps-1249-799.png",
      ],
      index: 0,
      active: 0,
    };
  },
  methods: {
    switchToimg(index) {
      if (index === 0) {
        this.active = 0;
      } else if (index === 1) {
        this.active = 1;
      } else if (index === 2) {
        this.active = 2;
      } else if (index === -1 && this.active !== 0) {
        // 前一张
        this.active--;
      } else if (index === 3 && this.active !== 2) {
        // 后一张
        this.active++;
      }
    },
  },
};
</script>

<style scoped>
.previous {
  position: absolute;
  width: 3.54%;
  height: 1.75%;
  top: 92.89682%;
  left: 43.081%;
  cursor: pointer;
}
.first {
  position: absolute;
  width: 1.51%;
  height: 1.75%;
  top: 92.89682%;
  left: 47.083%;
  cursor: pointer;
}
.second {
  position: absolute;
  width: 1.51%;
  height: 1.75%;
  top: 92.89682%;
  left: 49.17%;
  cursor: pointer;
}
.third {
  position: absolute;
  width: 1.51%;
  height: 1.75%;
  top: 92.89682%;
  left: 51.28%;
  cursor: pointer;
}
.next {
  position: absolute;
  width: 3.54%;
  height: 1.75%;
  top: 92.89682%;
  left: 53.33%;
  cursor: pointer;
}
section {
  color: #323232;
  font-family: "MicrosoftYaHei";
  font-size: 12px;
  background: #fff !important;
  margin: 0;
}

section .item {
  margin: 0;
  padding: 0;
  position: relative;
}

section .item img {
  margin: 0 auto;
  padding: 0;
  display: block;
}

.meal-item img {
  margin: 0 auto;
  padding: 0;
  display: block;
}

section .items {
  margin: 0;
  padding: 0;
  position: relative;
}

@media screen and (max-width: 1680px) {
  section {
    zoom: 0.875 !important;
  }
}
@media screen and (max-width: 1600px) {
  section {
    zoom: 0.833 !important;
  }
}
@media screen and (max-width: 1440px) {
  section {
    zoom: 0.75 !important;
  }
}
@media screen and (max-width: 1366px) {
  section {
    zoom: 0.711 !important;
  }
}

@media screen and (max-width: 1280px) {
  section {
    zoom: 0.667 !important;
  }
}
@media screen and (max-width: 1200px) {
  section {
    zoom: 0.625 !important;
  }
}

@media screen and (max-width: 1024px) {
  section {
    zoom: 0.533 !important;
  }
}
@media screen and (max-width: 960px) {
  section {
    zoom: 0.5 !important;
  }
}
@media screen and (max-width: 768px) {
  section {
    zoom: 0.4 !important;
  }
}
@media screen and (max-width: 480px) {
  section {
    zoom: 0.25 !important;
  }
}

.item,
.items {
  margin: auto;
  max-width: 1920px;
  min-width: 1200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

li {
  list-style: none;
}

.interviewvedio {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 25.63%;
  height: 20.41%;
}

.interviewartical {
  width: 25.42%;
  height: 2.74%;
  position: absolute;
}

.interviewaudio {
  width: 1.875%;
  height: 2.66%;
  position: absolute;
}

.firstvedio {
  top: 24.63%;
  left: 21.46%;
}

.firstartical {
  top: 48.08%;
  left: 21.56%;
}

.firstaudio {
  top: 18.75%;
  left: 45.16%;
}

.secondvedio {
  top: 24.63%;
  left: 52.76%;
}

.secondartical {
  top: 48.08%;
  left: 52.66%;
}

.secondaudio {
  top: 18.75%;
  left: 76.82%;
}

.thirdvedio {
  top: 62.65%;
  left: 21.46%;
}

.thirdartical {
  top: 86.54%;
  left: 21.56%;
}

.thirdaudio {
  top: 57.76%;
  left: 45.16%;
}

.fourthvedio {
  top: 62.65%;
  left: 52.76%;
}

.fourthartical {
  top: 86.54%;
  left: 52.66%;
}

.fourthaudio {
  top: 57.76%;
  left: 76.82%;
}
</style>
