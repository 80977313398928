<template>
  <div>
    <a
      href="https://www.tianxiawangshang.com/articledetail/270602"
      target="_blank"
      ><div class="banner">
        <img
          src="https://img.alicdn.com/imgextra/i2/O1CN01TnZu1K1r9ocwHo9Gh_!!6000000005589-0-tps-1920-500.jpg"
          alt=""
        /></div
    ></a>
    <!-- <a href="https://www.tianxiawangshang.com/activity/wsfh/wow" target="_blank"
      ><div class="topimg1" id="home">
        <video
          :class="{ video1: this.isie === false, video2: this.isie === true }"
          width="100%"
          autoplay
          loop
          muted
          src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/bj_1.mp4"
        ></video>
        <div :class="{ mid1: this.isie === false, mid2: this.isie === true }">
          <div
            :class="{
              middleimg1: this.isie === false,
              middleimg2: this.isie === true,
            }"
          >
            <img
              width="100%"
              src="https://img.alicdn.com/imgextra/i1/O1CN01AHUpcA1H3YiHHzbwD_!!6000000000702-2-tps-868-413.png"
              alt=""
            />
          </div>
        </div>
      </div>
      ></a
    > -->
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      isie: null,
    };
  },
  created() {
    this.isIE();
  },
  methods: {
    isIE() {
      //ie?
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        this.isie = true;
      } else {
        this.isie = false;
      }
    },
  },
};
</script>

<style>
.mid1 {
  width: 100%;
  position: absolute;
  top: 18%;
}
.mid2 {
  width: 100%;
  position: absolute;
  top: 12%;
}
.middleimg1 {
  width: 700px;
  height: 500px;
  margin: 0 auto;
}
.middleimg2 {
  width: 50%;
  height: 500px;
  margin: 0 auto;
}
.topimg1 {
  width: 100%;
  text-align: center;
  background-size: cover;
  margin-top: -10px;
  position: relative;
}
.video1 {
  object-fit: fill;
  width: 100%;
  height: 500px;
}
.video2 {
  width: 100%;
}
.banner {
  width: 100%;
  height: 500px;
  /* background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01jciGp71rrJunxKOJM_!!6000000005684-2-tps-1920-691.png");
  background-size: 100% 100%; */
  min-width: 1190px;
  margin: -10px auto 0;
  text-align: center;
}
.banner img {
  /* padding-top: 50px; */
}
</style>
